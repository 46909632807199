import React, { useEffect } from 'react';
import { AppProps } from 'next/app';
import { useProgressBar } from '~/hooks/config/useProgressBar';
import { useStyles } from '~/hooks/config/useStyles';
import { LayoutComponent, NextPageWithLayout } from '~/types/layout';
import { useWarnSelfXss } from '~/hooks/config/useWarnSelfXss';
import { useViewportTrimmer } from '~/hooks/config/useViewportTrimmer';
import { useCustomHead } from '~/hooks/config/useCustomHead';
import { useFixEmotionBug } from '~/hooks/config/useFixEmotionBug';
import { RecoilRoot, useSetRecoilState } from 'recoil';
import { global } from '~/styles/global';
import { reset } from '~/styles/reset';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useGTM } from '~/hooks/config/useGTM';
import { Toaster } from 'react-hot-toast';
import { errorToMessage } from '~/libs/errorToMessage';
import { showToastWithCache } from '~/libs/showToastThrottle';
import * as Sentry from '@sentry/nextjs';
import { fireGAEvent } from '~/libs/fireGAEvent';
import { classifyError } from '~/libs/classifyError';
import { errorMessagesState } from '~/stores/errorMessages';

import { useHubspotTracking } from '~/hooks/config/useHubspotTracking';
import { useDatadogRUM } from '~/hooks/config/useDatadogRUM';
import { useSalesforceTracking } from '~/hooks/config/useSalesforceTracking';

const LayoutEmpty: LayoutComponent = (props) => <>{props.children}</>;

const Layout: React.FC<{
  children: React.ReactNode;
  layoutComponent: LayoutComponent | undefined;
}> = (props) => {
  const InnerLayout = props.layoutComponent || LayoutEmpty;
  return <InnerLayout>{props.children}</InnerLayout>;
};

const CustomReactQueryProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const queryErrorHandler = (error: unknown) => {
    const errorType = classifyError(error);
    const message = errorToMessage(error);
    if (message) showToastWithCache(message);
    if (errorType === 'UNEXPECTED_ERROR' || errorType === 'SERVER_ERROR')
      Sentry.captureException(error);
  };

  const setMutationError = useSetRecoilState(errorMessagesState);

  const mutationErrorHandler = (error: unknown) => {
    const errorType = classifyError(error);
    const message = errorToMessage(error);
    if (message) setMutationError((currentValue) => [...currentValue, ...message.body]);
    if (errorType === 'UNEXPECTED_ERROR' || errorType === 'SERVER_ERROR')
      Sentry.captureException(error);
  };

  const queryClient = new QueryClient({
    defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
    queryCache: new QueryCache({
      onError: queryErrorHandler,
    }),
    mutationCache: new MutationCache({
      onError: mutationErrorHandler,
      onMutate: () => setMutationError([]),
    }),
  });

  useDatadogRUM();

  return <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>;
};

const MainApp = (appProps: AppProps) => {
  // custom layout
  // https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/
  const Component: NextPageWithLayout = appProps.Component;

  // hooks
  useWarnSelfXss();
  const viewportTrimmer = useViewportTrimmer(375);
  useGTM();
  useSalesforceTracking();

  useEffect(() => {
    fireGAEvent.initGA();
  }, []);

  const customHead = useCustomHead({ title: Component.config?.pageTitle });
  const progressBar = useProgressBar();
  const globalStyles = useStyles([global, reset]);
  const patchComponent = useFixEmotionBug();
  const hsTracking = useHubspotTracking();

  return (
    <React.StrictMode>
      <Layout layoutComponent={Component.Layout}>
        <RecoilRoot>
          <CustomReactQueryProvider>
            <Component {...appProps.pageProps} />
          </CustomReactQueryProvider>
        </RecoilRoot>
      </Layout>
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 8000,
        }}
      />

      {customHead.render()}
      {progressBar.renderStyles()}
      {globalStyles.renderStyles()}
      {patchComponent.render()}
      {hsTracking.render()}
      {viewportTrimmer.render()}
    </React.StrictMode>
  );
};

export default MainApp;
